import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/website.png";
import Home3 from "./Home3";
import Tilt from "react-parallax-tilt";

function Home2() {
  const [age, setAge] = useState(0);

  useEffect(() => {
    const calculateAge = () => {
      const birthDate = new Date("1998-05-26");
      const today = new Date();
      let currentAge = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
      const dayDiff = today.getDate() - birthDate.getDate();

      if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
        currentAge--;
      }

      setAge(currentAge);
    };

    calculateAge();
  }, []);

  return (
<section>
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em", textAlign: "left" }}>
              THIS IS <span className="purple"> ME </span>
            </h1>
            <p className="home-about-body">
              I'm a {age} year old, hard worker based in{" "}
              <b className="purple"> Ottawa, ON, Canada.</b>
              <br />
              <br />
              Fluent in
              <i>
                <b className="purple"> Spanish and English. </b>
              </i>
              <br />
              <br />
              Programmer with knowledge of &nbsp;
              <i>
                <b className="purple">C++, PHP, JAVA, Python, JavaScript.</b>
              </i>
              <br />
              <br />
              With 8+ years of with{" "}
              <b className="purple">customer service</b> /{" "}
              <b className="purple">retail</b> experience and 10+ years of&nbsp;
              <b className="purple"> programming </b> as a hobby and
              professional settings.
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img
                src={myImg}
                className="img-fluid"
                alt="avatar"
                style={{ width: "70%" }}
              />
            </Tilt>
          </Col>
        </Row>
  


      </Container>
    </Container>
      <Home3 />
    </section>
  );
}

export default Home2;
