import React from "react";
import { Col, Row } from "react-bootstrap";
import climbing from "../../Assets/climbing.png";
import { LiaSkiingSolid } from "react-icons/lia";
import { GiConsoleController } from "react-icons/gi";
import { MdScubaDiving } from "react-icons/md";
import {BiSolidPlaneAlt} from "react-icons/bi";

function Activities() {
  return (
    <Row style={{ justifyContent: "left", paddingBottom: "50px" }}>

      <Col xs={4} md={2}>
        <img src={climbing} className="img-fluid" alt="avatar" /> 
      </Col>

          <Col
            md={9}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >

	<blockquote className="blockquote mb-0">
<h2 style={{ justifyContent: "left", textAlign: "left"}}>Rock <strong className="purple"> Climbing </strong> </h2>
		<p style={{ textAlign: "justify"  }}>Rock climbing has been one of my primary passions for the past two years, during which I have made significant progress. I have developed a structured three-day routine that not only enhances my physical strength and endurance but also sharpens my mental focus. This routine has become a key part of maintaining a healthy work-life balance, ensuring that I stay energized, focused, and ready to tackle challenges both in my personal and professional life.
		</p>
	</blockquote> </Col>

      <Col xs={4} md={2} className="img-fluid" style={{   }}>

        <LiaSkiingSolid size={190}/>

      </Col>


          <Col
            md={9}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >

	<blockquote className="blockquote mb-0">
<h2 style={{ justifyContent: "left", textAlign: "left"}}><strong className="purple">Alpine </strong> Skiing / <strong className="purple"> Ice </strong> Skating</h2>
		<p style={{ textAlign: "justify"  }}>Over the past seven years, I have developed a growing passion for skiing and ice skating. Living in Ottawa provides the unique advantage of skating on the frozen Rideau Canal and having access to nearby ski centers like Camp Fortune. I make the most of each winter, spending time enjoying both of these activities, which have become an integral part of my seasonal routine.
		</p>
	</blockquote> </Col>



      <Col xs={4} md={2} className="img-fluid" style={{   }}>

        <GiConsoleController size={190}/>

      </Col>

          <Col
            md={9}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >

	<blockquote className="blockquote mb-0">
<h2 style={{ justifyContent: "left", textAlign: "left"}}>PC <strong className="purple"> Gaming </strong> </h2>
		<p style={{ textAlign: "justify"  }}>In my free time, I enjoy playing video games as a way to stay connected with friends and share memorable experiences. I mostly engage in multiplayer games like League of Legends, Counter Strike, and various party games such as Quiplash. While I might not be the best at gaming, I truly value the teamwork, camaraderie, and laughter that come from playing together.
		</p>
	</blockquote> </Col>
      <Col xs={4} md={2} className="img-fluid" style={{   }}>

        <BiSolidPlaneAlt size={190}/>

      </Col>


          <Col
            md={9}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >

	<blockquote className="blockquote mb-0">
<h2 style={{ justifyContent: "left", textAlign: "left"}}><strong className="purple">World </strong> Traveling  </h2>
		<p style={{ textAlign: "justify"  }}>Traveling the world is one of my greatest passions. I am always eager to explore new cultures, customs, and meet people from diverse backgrounds. Traveling enriches my perspective, allowing me to experience breathtaking landscapes and immerse myself in the beauty of different cultures and environments around the globe.
		</p>
	</blockquote> </Col>

      <Col xs={4} md={2} className="img-fluid" style={{   }}>

        <MdScubaDiving size={190}/>

      </Col>

          <Col
            md={9}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >

	<blockquote className="blockquote mb-0">
<h2 style={{ justifyContent: "left", textAlign: "left"}}>Scuba <strong className="purple"> Diving </strong> </h2>
		<p style={{ textAlign: "justify"  }}>As a certified PADI open water diver, I enjoy exploring diverse underwater locations. In the coming years, I aspire to become more involved in volunteer work as a scuba diver, contributing to environmental efforts such as garbage collection and pest control, while also continuing to explore new and exciting dive sites around the world.
		</p>
	</blockquote> </Col>

    </Row>
  );
}

export default Activities;
