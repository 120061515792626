import React, { useState } from "react"; // Import React and useState hook
import Navbar from "react-bootstrap/Navbar"; // Import Navbar component from react-bootstrap
import Nav from "react-bootstrap/Nav"; // Import Nav component for navigation items
import Container from "react-bootstrap/Container"; // Import Container for layout
import { Link } from "react-router-dom"; // Import Link for client-side routing
import {
  AiOutlineHome,
  AiOutlineUser,
} from "react-icons/ai"; // Import various icons from react-icons

import { CgFileDocument } from "react-icons/cg"; // Import document icon

function NavBar() {
  // State to manage the expanded state of the Navbar
  const [expand, updateExpanded] = useState(false);
  // State to manage the color of the Navbar based on scroll position
  const [navColour, updateNavbar] = useState(false);

  // Handler function to update the navbar color based on scroll position
  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true); // Update state to indicate navbar should change color
    } else {
      updateNavbar(false); // Reset navbar color state
    }
  }

  // Add scroll event listener to the window to call scrollHandler
  window.addEventListener("scroll", scrollHandler);

  return (
    // Render the Navbar component with responsive behavior
    <Navbar
      expanded={expand} // Controls whether the Navbar is expanded or collapsed
      fixed="top" // Keeps the Navbar fixed at the top of the viewport
      expand="md" // Specifies the breakpoint at which the Navbar will expand
      className={navColour ? "sticky" : "navbar"} // Applies different styles based on the navColour state
    >
      <Container>
        {/* Toggle button for expanding/collapsing the Navbar */}
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav" // Accessibility control for screen readers
          onClick={() => {
            // Update the expanded state when the toggle button is clicked
            updateExpanded(expand ? false : "expanded");
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        
        {/* Navbar content that collapses into a toggleable menu */}
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto" defaultActiveKey="#home">
            {/* Home link */}
            <Nav.Item>
              <Nav.Link as={Link} to="/" onClick={() => updateExpanded(false)}>
                <AiOutlineHome style={{ marginBottom: "2px" }} /> Home
              </Nav.Link>
            </Nav.Item>

            {/* About link */}
            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/about"
                onClick={() => updateExpanded(false)}
              >
                <AiOutlineUser style={{ marginBottom: "2px" }} /> About
              </Nav.Link>
            </Nav.Item>

            {/* Uncomment this section to add a Projects link */}
            {/*<Nav.Item>
              <Nav.Link
                href="http://web.dawnbrightsu.ca" // Link to external project website
                target="_blank" // Opens link in a new tab
                onClick={() => updateExpanded(false)}
              >
                <AiOutlineFundProjectionScreen
                  style={{ marginBottom: "2px" }}
                />{" "}
                Projects
              </Nav.Link>
            </Nav.Item>*/}

            {/* Resume link */}
            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/resume"
                onClick={() => updateExpanded(false)}
              >
                <CgFileDocument style={{ marginBottom: "2px" }} /> Resume
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar; // Export the NavBar component for use in other parts of the application
